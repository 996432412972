import * as React from "react"
import "../styles/global.scss"
import { graphql } from "gatsby"

export const query = graphql`
  {
    markdownRemark(fileAbsolutePath: { regex: "/index.md/" }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        description
        apiKey
        headline
        subline
        staging
      }
    }
  }
`

import Layout from "../components/layout"
import Seo from "../components/seo"
import Helmet from "react-helmet"

const IndexPage = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark

  const url =
    "https://www." +
    frontmatter.staging +
    "pamyra.de/plugin/pamyra4u.js?apikey=" +
    frontmatter.apiKey

  return (
    <Layout>
      <Seo title={frontmatter.title} description={frontmatter.description} />
      <Helmet>
        <base href="/" />
        <script src={url} async defer></script>
      </Helmet>
      <div className="h-screen w-full pb-20">
        <div className=" search flex flex-col justify-top flex-grow items-center w-full h-full items-stretch">
          <h1 className="-mt-8 text-center text-white sm:block">
            {frontmatter.headline}
          </h1>
          <p className="text-center text-white sm:block">
            {frontmatter.subline}
          </p>
          <pamyra-4u
            view="location"
            transport-objects-url="/booking"
            className="w-full"
          ></pamyra-4u>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
